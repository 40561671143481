import React, { useState } from 'react';
import { Form, Image, OverlayTrigger, Popover } from 'react-bootstrap';
import './nerveTableFilter.css';
import iconFilter from '../assets/icon_filter.png';

export default function NerveTableFilter({ filters, setFilters }) {
    const [active, setActive] = useState(false);
    // const [ filterValues, setFilterValues ] = useState(filters)

    const filterPopover = (
        <Popover>
            <Popover.Body>
                {Object.keys(filters).map(filterCategory => {
                    return (
                        <div key={filterCategory}>
                            <span className="filtersTitle">{filterCategory}</span>
                            {Object.keys(filters[filterCategory]).map(filter => {
                                return (
                                    <Form.Check
                                        key={filter}
                                        type="switch"
                                        label={filters[filterCategory][filter].label}
                                        defaultChecked={filters[filterCategory][filter].value}
                                        className="filtersOption"
                                        onChange={() => {
                                            setFilters((f) => ({
                                                ...f, [filterCategory]: {
                                                    ...f[filterCategory], [filter]: {...f[filterCategory][filter], value: !f[filterCategory][filter].value}
                                                } 
                                            }));
                                        }}
                                    />
                                );
                            })}
                        </div>
                    );
                })}
                {/* <span className="filtersTitle">Display filters</span>
                <Form.Check type="switch" label="Show archived shows" defaultChecked={filterValues.showArchived} className="filtersOption" onChange={() => {setFilterValues((fv) => ({...fv, showArchived: !fv.showArchived}))}} /> */}
            </Popover.Body>
        </Popover>
    );

    return (
        <OverlayTrigger
            trigger={'click'}
            placement={'left-start'}
            overlay={filterPopover}
            onEntered={() => {
                setActive(true);
            }}
            onExited={() => {
                setActive(false);
            }}
            rootClose
        >
            <button className={'filterBtn ' + (active && 'hover')}>
                <Image src={iconFilter} height={24} className="me-2" />
                Filters
            </button>
        </OverlayTrigger>
    );
}