import React from 'react';
import { Table } from 'react-bootstrap';
import './nerveTable.css';

export default function NerveTable({ data, rowIdentifier, dataToPass, dataLoader, headers, actions = [], allowSorting = null, sortedBy = null }) {
    return (
        <Table striped bordered hover className="align-middle text-center">
            <thead>
                <tr className="text-center">
                    {headers.map((header, index) => {
                        const columnCanBeSorted = (allowSorting && allowSorting[index]);
                        const isSorted = columnCanBeSorted && sortedBy[0] !== 0 && Math.abs(sortedBy[0]) === index+1;
                        const sortDirection = (columnCanBeSorted && isSorted) ? sortedBy[0] >= 0 ? 'asc' : 'desc' : 'asc';
                        return (
                            <th
                                key={index}
                                className={columnCanBeSorted ? 'sortable' : undefined}
                                onClick={columnCanBeSorted ? allowSorting[index] : undefined}
                            >
                                {header}
                                {columnCanBeSorted && <img src={require('../assets/sort_'+sortDirection+'.png')} alt="sort" className={'sortButton' + (isSorted ? ' active' : ' '+isSorted)} />}
                            </th>
                        );
                    })}
                    {actions.length > 0 && <th>Actions</th>}
                </tr>
            </thead>
            <tbody>
                {data.map((row) => {
                    return (
                        <tr key={rowIdentifier(row)} id={rowIdentifier(row)}>
                            {headers.map((_, index) => {
                                const passingData = dataToPass[index](row);
                                const extractedData = dataLoader[index](passingData, rowIdentifier(row));
                                return (
                                    <td key={index}>{extractedData}</td>
                                );
                            })}
                            {actions.length > 0 &&
                                <td>
                                    {actions.map((action, index) => {
                                        if(action?.showIf === undefined || (action?.showIf && action?.showIf(row))) return (
                                            <button key={index} onClick={() => { action.action(rowIdentifier(row)); }} className={'btn btn-' + (action?.bg || 'info')} style={{marginRight: 5}}>{action.label}</button>
                                        );
                                    })}
                                </td>
                            }
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
}