import React, { useEffect } from 'react';
import { Card, Col, Container, FormControl, FormLabel, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import './profile.css';
import { useSelector } from 'react-redux';

export default function Profile() {
    const profile = useSelector(state => state.profile.profile);
    const roles = useSelector(state => state.roles.roles);
    const myRole = roles.find(r => r.RoleID === profile?.Role)?.Name;

    useEffect(() => {
        document.title = process.env.REACT_APP_PAGE_TITLE+'Profile';
    }, []);
    
    return (
        <Container className="my-4" fluid="md">
            <h1>My Profile</h1>
            <hr />
            <p>This page includes all the information about your account at Nerve Radio. If any of the details are incorrect, please <Link to="/support">raise a ticket</Link>.</p>
            {profile !== undefined &&
                <Card>
                    <Card.Header><h4>Account Details</h4></Card.Header>
                    <Card.Body>
                        <Row id="profileDetails" className="text-center col-lg-9 mx-lg-auto">
                            <Col md={12} lg={6}>
                                <Row>
                                    <Col xs={4}><FormLabel>Name:</FormLabel></Col>
                                    <Col xs={8}><FormControl className="d-inline-block" readOnly value={profile?.Name || 'loading...'} /></Col>
                                </Row>
                            </Col>
                            <Col md={12} lg={6}>
                                <Row>
                                    <Col xs={4}><FormLabel>Email:</FormLabel></Col>
                                    <Col xs={8}><FormControl className="d-inline-block" readOnly value={profile?.Email || 'loading...'} /></Col>
                                </Row>
                            </Col>
                            <Col md={12} lg={6}>
                                <Row>
                                    <Col xs={4}><FormLabel>Nerve Role:</FormLabel></Col>
                                    <Col xs={8}><FormControl className="d-inline-block" readOnly value={myRole || 'loading...'} /></Col>
                                </Row>
                            </Col>
                            <Col md={12} lg={6}>
                                <Row>
                                    <Col xs={4}><FormLabel>Joined Nerve On:</FormLabel></Col>
                                    <Col xs={8}><FormControl className="d-inline-block" readOnly value={moment(profile?.JoinDate*1000).format('DD/MM/YYYY') || 'loading...'} /></Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            }
            <Card>
                <Card.Header><h4>Myriad Details</h4></Card.Header>
                <Card.Body>
                    <Row id="profileDetails" className="text-center col-lg-9 mx-lg-auto">
                        <Col md={12} lg={6}>
                            <Row>
                                <Col xs={4}><FormLabel>Username:</FormLabel></Col>
                                <Col xs={8}><FormControl className="d-inline-block" readOnly/></Col>
                            </Row>
                        </Col>
                        <Col md={12} lg={6}>
                            <Row>
                                <Col xs={4}><FormLabel>Password:</FormLabel></Col>
                                <Col xs={8}><Link to="/support" className="d-block text-start p-1">Click here to reset your password</Link></Col>
                            </Row>
                        </Col>
                        <Col md={12} lg={6}>
                            <Row>
                                <Col xs={4}><FormLabel>Cart Range:</FormLabel></Col>
                                <Col xs={8}><FormControl className="d-inline-block" readOnly /></Col>
                            </Row>
                        </Col>
                        <Col md={12} lg={6}>
                            <Row>
                                <Col xs={4}><FormLabel>Year of Study:</FormLabel></Col>
                                <Col xs={8}><FormControl className="d-inline-block" readOnly /></Col>
                            </Row>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Container>
    );
}