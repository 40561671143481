import { createSlice } from '@reduxjs/toolkit';

export const rolesSlice = createSlice({
    name: 'roles',
    initialState: {
        roles: []
    },
    reducers: {
        setRoles: (state, action) => {
            state.roles = action.payload.roles;
        }
    },
});

// Action creators are generated for each case reducer function
export const { setRoles } = rolesSlice.actions;

export default rolesSlice.reducer;