import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import { Accordion, Col, Container, Form, Row, Badge, Modal, Button } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import NerveTable from '../components/nerveTable';
import NerveTableFilter from '../components/nerveTableFilter';
import UserFinder from '../components/userFinder';
import UserName from '../components/userName';
import { removeAllShowSlots } from '../utils/reducers/scheduleSlice';
import { addHostToShow, addShow, archiveShow, removeHostFromShow, removeShow, restoreShow } from '../utils/reducers/showsSlice';
import './showsList.css';

export default function ShowsList({ api }) {
    const shows = useSelector(state => state.shows.shows);
    const schedule = useSelector(state => state.schedule.schedule);
    const users = useSelector(state => state.users.users);
    const reduxDispatch = useDispatch();

    const [tableFilters, setTableFilters] = useState({
        Display: {
            showCurrent: {
                label: 'Show active shows',
                value: true
            },
            showArchived: {
                label: 'Show archived shows',
                value: false
            }
        }
    });

    const [sortedBy, setSortedBy] = useState([0, '']);

    const handleSortChange = (columnIndex, columnKey) => {
        if (sortedBy[0] === columnIndex) {
            setSortedBy([-columnIndex, columnKey]);
        } else if (sortedBy[0] === -columnIndex) {
            setSortedBy([0, '']);
        } else if (sortedBy[0] !== columnIndex) {
            setSortedBy([columnIndex, columnKey]);
        } else {
            setSortedBy([columnIndex, columnKey]);
        }
    };

    const [newShowData, setNewShowData] = useState({});
    const [showCoHostModal, setShowCoHostModal] = useState(false);
    const [selectedCoHost, setSelectedCoHost] = useState(null);
    useEffect(() => {
        document.title = process.env.REACT_APP_PAGE_TITLE + 'Shows';
    }, []);

    const userFinderRef = useRef();
    const hostFinderRef = useRef();

    const createNewShow = (event) => {
        event.preventDefault();
        if (newShowData?.name === undefined) return toast.error('No show name set.');
        if (newShowData.name.length < 3) return toast.error('Show Name must be more than 3 characters long.');
        if (newShowData?.host === undefined) return toast.error('No host selected.');

        api.createShow(newShowData.name, newShowData.host.id).then((result) => {
            if (result) {
                toast.success('Show was successfully added.');
                document.getElementById('newShowForm').reset();
                userFinderRef.current?.clear();
                setNewShowData({});
                reduxDispatch(addShow({
                    ShowID: result,
                    Name: newShowData.name,
                    Host: newShowData.host.id,
                    Hosts: [],
                    Description: '',
                    Image: '',
                    CreatedDate: Math.round(Date.now() / 1000),
                    Archived: 0
                }));
            } else {
                toast.error(result.error);
            }
        }).catch(error => {
            console.warn(error);
            toast.error('An error has occurred whilst adding the show: ' + error.message);
        });
    };

    const shouldShow = (show) => {
        if (tableFilters.Display.showCurrent.value === true && show.Archived != tableFilters.Display.showCurrent.value) return true;
        if (tableFilters.Display.showArchived.value === true && show.Archived == tableFilters.Display.showArchived.value) return true;
        return false;
    };

    const filteredShows = [];
    shows.forEach(s => { if (shouldShow(s)) filteredShows.push(s); });
    if (sortedBy[0] !== 0) {
        filteredShows.sort((a, b) => {
            if (sortedBy[0] > 0) {
                if (a[sortedBy[1]] < b[sortedBy[1]]) return -1;
                if (a[sortedBy[1]] > b[sortedBy[1]]) return 1;
                return 0;
            } else {
                if (a[sortedBy[1]] < b[sortedBy[1]]) return 1;
                if (a[sortedBy[1]] > b[sortedBy[1]]) return -1;
                return 0;
            }
        });
    }

    const addCoHost = (showId) => {
        setShowCoHostModal(showId);
    };

    return (
        <Container className="my-4">
            <Modal show={showCoHostModal} onHide={() => { setShowCoHostModal(false); }}>
                <Modal.Header closeButton>
                    <Modal.Title>{shows.find(s => s.ShowID === showCoHostModal)?.Name} - Add Co-Host</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Co-Host</Form.Label>
                            <UserFinder passedRef={hostFinderRef} placeholder="Existing Nerve User Name or Email" includeOnly={users.filter(user => user.Archived == 0)} onUserSelected={setSelectedCoHost} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setShowCoHostModal(false); }}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => {
                        if (selectedCoHost === null) return toast.error('No co-host selected.');
                        api.addHostToShow(showCoHostModal, selectedCoHost.id).then((result) => {
                            if (result) {
                                toast.success('Co-Host was successfully added.');
                                setShowCoHostModal(false);
                                setSelectedCoHost(null);
                                reduxDispatch(addHostToShow({ ShowID: showCoHostModal, Host: selectedCoHost.id }));
                            } else {
                                toast.error(result.error);
                            }
                        }).catch(error => {
                            console.warn(error);
                            toast.error(error.message);
                        });
                    }}>
                        Add Co-Host
                    </Button>
                </Modal.Footer>
            </Modal>
            <h1 style={{ float: 'left' }}>Shows List</h1>
            <NerveTableFilter
                filters={tableFilters}
                setFilters={setTableFilters}
            />
            <hr />
            <Container className="mb-3 p-0">
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><span className="fw-bold text-end w-100 me-2">New Show</span></Accordion.Header>
                        <Accordion.Body>
                            <Form id="newShowForm">
                                <Row className="text-center">
                                    <Col sm={12} lg={5} className="mb-3">
                                        <Form.Label className="fw-bold">Show Name: </Form.Label>
                                        <Form.Control placeholder="Name e.g. 'Tech Team Takeover Hour'" onChange={(e) => { setNewShowData(s => { return { ...s, name: e.target.value }; }); }} />
                                    </Col>
                                    <Col sm={12} lg={5} className="mb-3">
                                        <Form.Label className="fw-bold">Main Host: </Form.Label>
                                        <UserFinder passedRef={userFinderRef} placeholder="Existing Nerve User Name or Email" includeOnly={users.filter(user => user.Archived !== 1)} onUserSelected={(user) => { setNewShowData(s => { return { ...s, host: user }; }); }} />
                                    </Col>
                                    <Col sm={12} lg={2} className="mb-3">
                                        <button className="btn btn-primary h-100" onClick={createNewShow}>Create show</button>
                                    </Col>
                                </Row>
                            </Form>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Container>
            {filteredShows.length === 0 &&
                <span className="text-secondary fw-bold fs-5 text-center d-block">No shows found based on the given filters.</span>
            }
            {filteredShows.length > 0 &&
                <NerveTable
                    data={filteredShows}
                    headers={['Show Name', 'Main Host', 'Co-Presenters', 'Description']}
                    dataToPass={[
                        data => data.Name,
                        data => data.Host,
                        data => data.Hosts,
                        data => data.Description
                    ]}
                    dataLoader={[
                        (name, rowID) => { return <Link to={'/shows/' + rowID}>{name}</Link>; },
                        (host) => { return <UserName uid={host} />; },
                        (hosts, rowID) => {
                            return (
                                <>
                                    {hosts.map((Host, index) => {
                                        return (
                                            <Badge
                                                key={index}
                                                pill bg='none'
                                                className='cohost'
                                                onClick={() => {
                                                    if(!confirm('Are you sure you want to remove this co-host?')) return;
                                                    api.removeHostFromShow(rowID, Host).then((result) => {
                                                        if (result) {
                                                            toast.success('Co-Host was successfully removed.');
                                                            reduxDispatch(removeHostFromShow({ ShowID: rowID, Host: Host }));
                                                        } else {
                                                            toast.error(result.error);
                                                        }
                                                    }).catch(error => {
                                                        console.warn(error);
                                                        toast.error(error.message);
                                                    });
                                                }}
                                            >
                                                <UserName uid={Host} />
                                            </Badge>
                                        );
                                    })}
                                    {hosts.length <= 2 && <Badge pill bg='success' className='add' onClick={() => addCoHost(rowID)}>+</Badge>}
                                </>
                            );
                        },
                        (desc) => { return <span>{desc.substr(0, 64)}{desc.length > 64 && '...'}</span>; }
                    ]}
                    api={api}
                    rowIdentifier={row => row.ShowID}
                    actions={[
                        {
                            label: 'Archive',
                            bg: 'secondary',
                            showIf: (show) => show.Archived === 0,
                            action: (sid) => {
                                if (schedule.filter(s => s.ShowID === sid).length > 0) {
                                    let scheduledTimes = '';
                                    schedule.filter(s => s.ShowID === sid).forEach(s => {
                                        scheduledTimes += moment().isoWeekday(parseInt(s.Day)).format('dddd') + ' @ ' + s.TimeStart + '-' + s?.TimeEnd + '\n';
                                    });
                                    if (!window.confirm(`This show is currently scheduled to air. Are you sure you want to archive it?\nArchiving shows will permamently remove any of their schedules\n\n${scheduledTimes}`)) return;
                                }
                                api.archiveShow(sid).then(result => {
                                    if (result) {
                                        toast.success('Show archived successfully.');
                                        reduxDispatch(archiveShow({
                                            id: sid
                                        }));
                                        reduxDispatch(removeAllShowSlots({
                                            id: sid
                                        }));
                                    }
                                }).catch(error => {
                                    toast.error(error.message);
                                });
                            }
                        },
                        {
                            label: 'Restore',
                            bg: 'success',
                            showIf: (show) => show.Archived !== 0,
                            action: (sid) => {
                                api.restoreShow(sid).then(result => {
                                    if (result) {
                                        toast.success('Show restored successfully.');
                                        reduxDispatch(restoreShow({
                                            id: sid
                                        }));
                                    }
                                }).catch(error => {
                                    toast.error(error.message);
                                });
                            }
                        },
                        {
                            label: 'Delete',
                            bg: 'danger',
                            action: (sid) => {
                                if (schedule.filter(s => s.ShowID === sid).length > 0) {
                                    let scheduledTimes = '';
                                    schedule.filter(s => s.ShowID === sid).forEach(s => {
                                        scheduledTimes += moment().isoWeekday(parseInt(s.Day)).format('dddd') + ' @ ' + s.TimeStart + '-' + s?.TimeEnd + '\n';
                                    });
                                    if (!window.confirm(`This show is currently scheduled to air. Are you sure you want to delete it?\nDeleting shows will permamently remove any of their schedules\n\n${scheduledTimes}`)) return;
                                } else {
                                    if (!window.confirm('Are you sure you want to delete this show?')) return;
                                }
                                api.deleteShow(sid).then(result => {
                                    if (result) {
                                        toast.success('Show deleted successfully.');
                                        reduxDispatch(removeShow({
                                            id: sid
                                        }));
                                        reduxDispatch(removeAllShowSlots({
                                            id: sid
                                        }));
                                    }
                                }).catch(error => {
                                    toast.error(error.message);
                                });
                            }
                        }
                    ]}
                    allowSorting={[
                        () => { handleSortChange(1, 'Name'); }
                    ]}
                    sortedBy={sortedBy}
                />
            }
            <span className="text-secondary fs-6 text-center d-block">{filteredShows.length} shows shown out of {shows.length} in record. Apply filters to view more shows.</span>
        </Container>
    );
}