import { createSlice } from '@reduxjs/toolkit';

export const showsSlice = createSlice({
    name: 'shows',
    initialState: {
        shows: []
    },
    reducers: {
        setShows: (state, action) => {
            state.shows = action.payload.shows;
        },
        addShow: (state, action) => {
            state.shows.push(action.payload);
        },
        updateShow: (state, action) => {
            const showIndex = state.shows.findIndex(s => s.ShowID === action.payload.id);
            state.shows[showIndex] = {
                ...state.shows[showIndex],
                Name: action.payload.Name,
                Host: action.payload.Host,
                Hosts: state.shows[showIndex].Hosts.filter(h => h !== action.payload.Host),
                Description: action.payload.Description,
                Record: action.payload.Record,
                Instagram: action.payload.Instagram,
            };
        },
        archiveShow: (state, action) => {
            const showIndex = state.shows.findIndex(s => s.ShowID === action.payload.id);
            state.shows[showIndex] = {
                ...state.shows[showIndex],
                Archived: 1,
            };
        },
        restoreShow: (state, action) => {
            const showIndex = state.shows.findIndex(s => s.ShowID === action.payload.id);
            state.shows[showIndex] = {
                ...state.shows[showIndex],
                Archived: 0,
            };
        },
        removeShow: (state, action) => {
            state.shows = state.shows.filter(s => s.ShowID !== action.payload.id);
        },
        archiveAllUserShows: (state, action) => {
            state.shows = state.shows.map(s => s.Host !== action.payload.id ? s : { ...s, Archived: 1 });
        },
        deleteAllUserShows: (state, action) => {
            state.shows = state.shows.filter(s => s.Host !== action.payload.id);
        },
        addHostToShow: (state, action) => {
            const showIndex = state.shows.findIndex(s => s.ShowID === action.payload.ShowID);
            state.shows[showIndex] = {
                ...state.shows[showIndex],
                Hosts: [...state.shows[showIndex].Hosts, action.payload.Host]
            };
        },
        removeHostFromShow: (state, action) => {
            const showIndex = state.shows.findIndex(s => s.ShowID === action.payload.ShowID);
            state.shows[showIndex] = {
                ...state.shows[showIndex],
                Hosts: state.shows[showIndex].Hosts.filter(h => h !== action.payload.Host)
            };
        },
    },
});

// Action creators are generated for each case reducer function
export const { setShows, updateShow, addShow, archiveShow, restoreShow, removeShow, archiveAllUserShows, deleteAllUserShows, addHostToShow, removeHostFromShow } = showsSlice.actions;

export default showsSlice.reducer;