import { createSlice } from '@reduxjs/toolkit';

export const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        profile: []
    },
    reducers: {
        setMyProfile: (state, action) => {
            state.profile = action.payload.profile;
        }
    },
});

// Action creators are generated for each case reducer function
export const { setMyProfile } = profileSlice.actions;

export default profileSlice.reducer;