import React, { useEffect, useState, useRef } from 'react';
import { Accordion, Col, Container, Form, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import NerveTable from '../components/nerveTable';
import NerveTableFilter from '../components/nerveTableFilter';
import UserFinder from '../components/userFinder';
import UserIcon from '../components/userIcon';
import UserName from '../components/userName';
import UserRole from '../components/userRole';
import { addUser, archiveUser, removeUser, restoreUser } from '../utils/reducers/usersSlice';
import { archiveAllUserShows, deleteAllUserShows } from '../utils/reducers/showsSlice';
import { removeShowsFromSchedule } from '../utils/reducers/scheduleSlice';
import { useNavigate } from 'react-router-dom';

export default function UsersList({ api }) {
    const users = useSelector((state) => state.users.users);
    const roles = useSelector((state) => state.roles.roles);
    const shows = useSelector((state) => state.shows.shows);
    const schedule = useSelector((state) => state.schedule.schedule);
    const profile = useSelector(state => state.profile.profile);
    const myRole = profile?.Role;
    const reduxDispatch = useDispatch();
    const navigate = useNavigate();

    const [newUserData, setNewUserData] = useState({});
    const [tableFilters, setTableFilters] = useState({
        Users: {
            showAdmins: {
                label: 'Show admins',
                value: true
            },
            showCommittee: {
                label: 'Show committee',
                value: true
            },
            showPresenters: {
                label: 'Show presenters',
                value: true
            },
            showArchived: {
                label: 'Show archived only',
                value: false
            }
        },
        Groups: {
            daytime: {
                label: <span style={{textDecoration: 'underline wavy red'}} title='TODO: Decide if this is needed'>Show daytime presenters</span>,
                value: true
            },
            evenings: {
                label: <span style={{textDecoration: 'underline wavy red'}} title='TODO: Decide if this is needed'>Show evening presenters</span>,
                value: true
            }
        }
    });

    const [sortedBy, setSortedBy] = useState([0, '']);

    const handleSortChange = (columnIndex, columnKey) => {
        if (sortedBy[0] === columnIndex) {
            setSortedBy([-columnIndex, columnKey]);
        } else if (sortedBy[0] === -columnIndex) {
            setSortedBy([0, '']);
        } else if (sortedBy[0] !== columnIndex) {
            setSortedBy([columnIndex, columnKey]);
        } else {
            setSortedBy([columnIndex, columnKey]);
        }
    };

    useEffect(() => {
        document.title = process.env.REACT_APP_PAGE_TITLE + 'Users';
    }, []);

    const userFinderRef = useRef();

    const createNewUser = (event) => {
        event.preventDefault();
        if (newUserData?.user === undefined) return toast.error('No account selected.');
        if (newUserData?.role === undefined) return toast.error('No role selected.');

        api.createUser(newUserData.user.id, newUserData.role, newUserData.user.givenName + ' ' + newUserData.user.surname, newUserData.user.mail).then((result) => {
            if (result) {
                toast.success('User successfully added.');
                document.getElementById('newUserForm').reset();
                userFinderRef.current?.clear();
                reduxDispatch(addUser({
                    UserID: newUserData.user.id,
                    Role: parseInt(newUserData.role),
                    Name: newUserData.user.givenName + ' ' + newUserData.user.surname,
                    Email: newUserData.user.mail,
                    JoinDate: Math.round(Date.now() / 1000),
                    Archived: 0
                }));
                setNewUserData({});
            }
        }).catch(error => {
            console.warn(error);
            toast.error(error.message);
        });
    };

    const shouldShow = (user) => {
        let willShow = false;
        if (tableFilters.Users.showAdmins.value === true && user.Role === 1) willShow = true;
        if (tableFilters.Users.showCommittee.value === true && user.Role === 2) willShow = true;
        if (tableFilters.Users.showPresenters.value === true && user.Role === 3) willShow = true;
        if (willShow) {
            if (tableFilters.Users.showArchived.value === true) {
                if (user.Archived === 1) return true;
            }else{
                if (user.Archived === 0) return true;
            }
        }
        return false;
    };

    let filteredUsers = [];
    users.forEach(u => { if (shouldShow(u)) filteredUsers.push(u); });
    if (sortedBy[0] !== 0) {
        filteredUsers.sort((a, b) => {
            if (sortedBy[0] > 0) {
                if (a[sortedBy[1]] < b[sortedBy[1]]) return -1;
                if (a[sortedBy[1]] > b[sortedBy[1]]) return 1;
                return 0;
            } else {
                if (a[sortedBy[1]] < b[sortedBy[1]]) return 1;
                if (a[sortedBy[1]] > b[sortedBy[1]]) return -1;
                return 0;
            }
        });
    }

    return (
        <Container className="my-4">
            <h1 style={{ float: 'left' }}>Users List</h1>
            <NerveTableFilter
                filters={tableFilters}
                setFilters={setTableFilters}
            />
            <hr />
            <Container className="mb-3 p-0">
                <Accordion>
                    <Accordion.Item eventKey="0" >
                        <Accordion.Header><span className="fw-bold text-end w-100 me-2">New User</span></Accordion.Header>
                        <Accordion.Body>
                            <Form id="newUserForm">
                                <Row className="text-center">
                                    <Col sm={12} lg={5} className="mb-3">
                                        <Form.Label className="fw-bold">Account: </Form.Label>
                                        <UserFinder passedRef={userFinderRef} excludeUsers={users} onUserSelected={(user) => { setNewUserData(u => { return { ...u, user: user }; }); }} />
                                    </Col>
                                    <Col sm={12} lg={5} className="mb-3">
                                        <Form.Label className="fw-bold">Role: </Form.Label>
                                        <Form.Select defaultValue={''} onChange={(e) => { setNewUserData(u => { return { ...u, role: e.target.value }; }); }}>
                                            <option disabled value=""></option>
                                            {roles.map((role, index) => {
                                                return (
                                                    <option key={index} value={role.RoleID}>{role.Name}</option>
                                                );
                                            })}
                                        </Form.Select>
                                    </Col>
                                    <Col sm={12} lg={2} className="mb-3">
                                        <button className="btn btn-primary h-100" onClick={createNewUser}>Create user</button>
                                    </Col>
                                </Row>
                            </Form>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Container>
            {filteredUsers.length === 0 &&
                <span className="text-secondary fw-bold fs-5 text-center d-block">No users found based on the given filters.</span>
            }
            {filteredUsers.length > 0 &&
                <NerveTable
                    data={filteredUsers}
                    headers={['', 'Name', 'Role', <span key={3} title='TODO: Decide if this is needed' style={{textDecoration: 'underline wavy red'}}>Groups</span>]}
                    dataToPass={[
                        (data) => { return data.UserID; },
                        (data) => { return data.UserID; },
                        (data) => { return data.Role; },
                        () => { }
                    ]}
                    dataLoader={[
                        (id) => { return (<UserIcon uid={id} />); },
                        (id) => { return <UserName uid={id} />; },
                        (id) => { return <UserRole rid={id} />; },
                        () => { return <span></span>; }
                    ]}
                    api={api}
                    rowIdentifier={row => row.UserID}
                    actions={[
                        {
                            label: 'Edit',
                            bg: 'info',
                            showIf: (user) => (myRole < user.Role || myRole === 1),
                            action: (uid) => {
                                navigate('/users/' + uid);
                            }
                        },
                        {
                            label: 'Archive',
                            bg: 'secondary',
                            showIf: (user) => (user.Archived === 0 && (myRole < user.Role || myRole === 1)),
                            action: (uid) => {
                                // Check for any shows with their respective schedules slots
                                let userShowsIds = [];
                                if (shows.filter(show => show.Archived !== 1).filter(show => show.Host === uid).length > 0) {
                                    let userShows = {};
                                    let scheduleCount = 0;
                                    shows.filter(show => show.Archived !== 1).filter(show => show.Host === uid).forEach(show => {
                                        userShowsIds.push(show.ShowID);
                                        if (!userShows[show.Name]) userShows[show.Name] = [];
                                        if (schedule.filter(slot => slot.ShowID === show.ShowID).length > 0) {
                                            schedule.filter(slot => slot.ShowID === show.ShowID).forEach(slot => {
                                                userShows[show.Name].push(moment().isoWeekday(parseInt(slot.Day)).format('dddd') + ' @ ' + slot.TimeStart + '-' + slot?.TimeEnd + '\n');
                                                scheduleCount++;
                                            });
                                        }
                                    });
                                    let scheduledTimes = '';
                                    Object.keys(userShows).forEach(show => {
                                        scheduledTimes += '\n' + show + ':\n';
                                        userShows[show].forEach(slot => {
                                            scheduledTimes += '      - ' + slot + '';
                                        });
                                    });
                                    if (!window.confirm(
                                        'This user has existing shows. Upon archiving the user, all their shows will be archived too, with any schedules permamently deleted.\n' +
                                        `Are you sure you want to archive it?\n${scheduledTimes}`)) return;
                                    if (scheduleCount >= 3) {
                                        if (!window.confirm('This user has more than 3 scheduled slots, are you ABSOLUTELY CERTAIN that you wish to archive this user?')) return;
                                    }
                                } else {
                                    if (!window.confirm('Are you sure you want to archive this user?')) return;
                                }

                                api.archiveUser(uid).then(result => {
                                    if (result) {
                                        toast.success('User archived successfully.');
                                        reduxDispatch(archiveUser({
                                            id: uid
                                        }));
                                        reduxDispatch(archiveAllUserShows({
                                            id: uid
                                        }));
                                        reduxDispatch(removeShowsFromSchedule({
                                            ids: userShowsIds
                                        }));
                                    }
                                }).catch(error => {
                                    toast.error(error.message);
                                });
                            }
                        },
                        {
                            label: 'Restore',
                            bg: 'success',
                            showIf: (user) => (user.Archived !== 0 && (myRole < user.Role || myRole === 1)),
                            action: (uid) => {
                                api.restoreUser(uid).then(result => {
                                    if (result) {
                                        toast.success('User restored successfully.');
                                        reduxDispatch(restoreUser({
                                            id: uid
                                        }));
                                    }
                                }).catch(error => {
                                    toast.error(error.message);
                                });
                            }
                        },
                        {
                            label: 'Delete',
                            bg: 'danger',
                            showIf: (user) => ((myRole < user.Role || myRole === 1)),
                            action: (uid) => {
                                // Check for any shows with their respective schedules slots
                                let userShowsIds = [];
                                if (shows.filter(show => show.Archived !== 1).filter(show => show.Host === uid).length > 0) {
                                    let userShows = {};
                                    let scheduleCount = 0;
                                    shows.filter(show => show.Archived !== 1).filter(show => show.Host === uid).forEach(show => {
                                        userShowsIds.push(show.ShowID);
                                        if (!userShows[show.Name]) userShows[show.Name] = [];
                                        if (schedule.filter(slot => slot.ShowID === show.ShowID).length > 0) {
                                            schedule.filter(slot => slot.ShowID === show.ShowID).forEach(slot => {
                                                userShows[show.Name].push(moment().isoWeekday(parseInt(slot.Day)).format('dddd') + ' @ ' + slot.TimeStart + '-' + slot?.TimeEnd + '\n');
                                                scheduleCount++;
                                            });
                                        }
                                    });
                                    let scheduledTimes = '';
                                    Object.keys(userShows).forEach(show => {
                                        scheduledTimes += '\n' + show + ':\n';
                                        userShows[show].forEach(slot => {
                                            scheduledTimes += '      - ' + slot + '';
                                        });
                                    });
                                    if (!window.confirm(
                                        'This user has existing shows. Upon deleting the user, all their shows with any schedules will be permamently deleted.\n' +
                                        `Are you sure you want to delete it?\n${scheduledTimes}`)) return;
                                    if (scheduleCount >= 3) {
                                        if (!window.confirm('This user has more than 3 scheduled slots, are you ABSOLUTELY CERTAIN that you wish to delete this user?')) return;
                                    }
                                } else {
                                    if (!window.confirm('Are you sure you want to delete this user?')) return;
                                }

                                api.deleteUser(uid).then(result => {
                                    if (result) {
                                        toast.success('User deleted successfully.');
                                        reduxDispatch(removeUser({ id: uid }));
                                        reduxDispatch(deleteAllUserShows({
                                            id: uid
                                        }));
                                        reduxDispatch(removeShowsFromSchedule({
                                            ids: userShowsIds
                                        }));
                                    }
                                }).catch(error => {
                                    console.warn(error);
                                    toast.error(error.message);
                                });
                            }
                        }
                    ]}
                    allowSorting={[
                        false,
                        () => { handleSortChange(2, 'Name'); },
                        () => { handleSortChange(3, 'Role'); },
                        false,
                        false,
                    ]}
                    sortedBy={sortedBy}
                />
            }
            <span className="text-secondary fs-6 text-center d-block">{filteredUsers.length} users shown out of {users.length} in record. Apply filters to view more shows.</span>
        </Container>
    );
}