import moment from 'moment';
import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import './home.css';

export default function Home(){
    useEffect(() => {
        document.title = process.env.REACT_APP_PAGE_TITLE.slice(0,-3);
    }, []);

    return (
        <Container className="my-4">
            <h1>Home Page</h1>
            <hr></hr>
            <Container className="footerCredits">
                <p>
                    <small title={'(Built on '+moment(process.env.REACT_APP_VERSION.split('-')[1]*1000).format('DD/MM/YYYY HH:mm:ss')+')'}>
                        Nerve Manager v{process.env.REACT_APP_VERSION.split('-')[0]+(process.env.NODE_ENV === 'development' ? '-dev' : '')}
                    </small> | <small>
                        Developed by <a href="https://tamirslo.dev" target={'_blank'} rel="noreferrer">Tamir Slobodskoy</a> &copy; 2021 - {moment().format('YYYY')}
                    </small>
                </p>
            </Container>
        </Container>
    );
}