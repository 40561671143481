import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, Col, Container, FormControl, FormLabel, FormSelect, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { removeSlot, updateSlot } from '../utils/reducers/scheduleSlice';
import './newScheduleSlot.css';

export default function EditScheduleSlot({ api }){

    const daysArray = new Array(7).fill().map((_, i) => i+1);
    const hoursArray = new Array(24).fill().map((_, i) => moment({hour: i}).format('HH:mm'));

    const { id: givenID } = useParams();
    const navigate = useNavigate();
    const schedule = useSelector(state => state.schedule.schedule);
    const shows = useSelector(state => state.shows.shows);
    const slot = schedule.find(s => s.ScheduleID === parseInt(givenID));
    const [ tempSlot, setTempSlot ] = useState(null);

    const reduxDispatch = useDispatch();
    
    useEffect(() => {
        if(slot !== undefined && tempSlot === null) setTempSlot(slot);
    }, [slot]);
    
    if(slot === undefined || tempSlot === null) return <div />;

    const onDayChange = (value) => {
        setTempSlot(s => { return {...s, Day: value};});
    };

    const onShowChanged = (show) => {
        if(show[0] === undefined) setTempSlot(s => {return {...s, ShowID: null};});
        else setTempSlot(s => { return {...s, ShowID: show[0].ShowID};});
    };

    const onStartTimeChange = (value) => {
        const newTimeStart = moment({hour: value});
        const oldTimeEnd = moment({hour: tempSlot?.TimeEnd || moment(slot.TimeEnd, 'HH:mm').add({hour: 1}).format('HH')});
        setTempSlot(s => { return {...s, TimeStart: newTimeStart.format('HH:mm')};});
        if(newTimeStart - oldTimeEnd >= 0){
            if(oldTimeEnd.format('HH') === '00') return;
            setTempSlot(s => {
                const newHour = moment({hour: newTimeStart.hour()}).add({hours: 1}).format('HH');
                return {...s, TimeEnd: newHour !== '00' ? newHour+':00' : '24:00'};
            });
        }
    };

    const onEndTimeChange = (value) => {
        const newTimeEnd = value !== '24' ? moment({hour: value}).format('HH:mm') : '24:00';
        setTempSlot(s => { return {...s, TimeEnd: newTimeEnd};});
    };

    const scheduleShow = () => {
        const sDay = tempSlot.Day;
        const sShow = tempSlot.ShowID;
        const sTimeStart = tempSlot.TimeStart;
        const sTimeEnd = tempSlot.TimeEnd;
        api.updateScheduleSlot(slot.ScheduleID, sDay, sShow, sTimeStart, sTimeEnd).then(() => {
            toast.success('Schedule was updated successfully.');
            reduxDispatch(updateSlot({
                id: slot.ScheduleID,
                Day: sDay,
                Show: sShow,
                TimeStart: sTimeStart,
                TimeEnd: sTimeEnd
            }));
            navigate('/shows/schedule');
        }).catch(error => {
            console.warn(error);
            toast.error(error.message);
        });
    };

    const deleteSlot = () => {
        if(window.confirm('Are you sure you want to delete this scheduled slot?'))
            api.deleteScheduleSlot(slot.ScheduleID).then(() => {
                toast.success('The slot was deleted successfully.');
                reduxDispatch(removeSlot({
                    id: slot.ScheduleID
                }));
                navigate('/shows/schedule');
            }).catch(error => {
                console.warn(error);
                toast.error(error.message);
            });
    };

    const openShow = () => {
        navigate(`/shows/${tempSlot?.ShowID || slot.ShowID}`);
    };

    const hasChanged = (key) => {
        if(key === undefined){
            let changeNoticed = false;
            Object.keys(slot).forEach((oKey) => {
                if(changeNoticed) return;
                if(slot[oKey] !== tempSlot[oKey]) changeNoticed = true;
            });
            return changeNoticed;
        }
        return slot[key] !== tempSlot[key];
    };

    const activeShows = shows.filter(show => show.Archived !== 1);

    return (
        <Container className="my-4">
            <h1>Editing slot on {slot !== null && moment().isoWeekday(parseInt(slot?.Day)).format('dddd')} at {slot?.TimeStart}</h1>
            <hr />
            <Card>
                <Card.Header>
                    <h4 className="d-inline-block">Slot Details</h4>
                    <button className="btn btn-danger float-end ms-2" onClick={deleteSlot}>Delete</button>
                    <button className="btn btn-secondary float-end ms-2" onClick={openShow}>Edit Show</button>
                    {typeof tempSlot?.ShowID === 'number' && hasChanged() && <button className="btn btn-success float-end" onClick={scheduleShow}>Save</button>}
                </Card.Header>
                <Card.Body>
                    {(slot === null || activeShows.length === 0) &&
                        <p>Loading available shows...</p>
                    }
                    {slot !== null && activeShows.length !== 0 &&
                        <Row id="slotDetails" className="text-center col-lg-9 mx-lg-auto">
                            <Col md={12} lg={6}>
                                <Row>
                                    <Col xs={4}><FormLabel>Day:</FormLabel></Col>
                                    <Col xs={8}>
                                        <FormSelect className="d-inline-block" value={tempSlot?.Day || slot.Day} onChange={(e) => onDayChange(e.target.value)} isValid={hasChanged('Day')}>
                                            {daysArray.map((day, index) => {
                                                return <option key={index} value={day}>{moment().isoWeekday(day).format('dddd')}</option>;
                                            })}
                                        </FormSelect>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} lg={6}>
                                <Row>
                                    <Col xs={4}><FormLabel>Show:</FormLabel></Col>
                                    <Col xs={8}>
                                        <Typeahead
                                            id="showSelector"
                                            options={activeShows}
                                            labelKey={(show) => `${show.Name}`}
                                            flip
                                            onChange={onShowChanged}
                                            isValid={typeof tempSlot?.ShowID === 'number' && hasChanged('ShowID')}
                                            defaultSelected={shows.filter(s => s.ShowID === slot.ShowID)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} lg={6}>
                                <Row>
                                    <Col xs={4}><FormLabel>Time Start:</FormLabel></Col>
                                    <Col xs={8}>
                                        <FormSelect className="d-inline-block"  value={moment({hour: tempSlot?.TimeStart || slot.TimeStart}).format('HH')} onChange={(e) => onStartTimeChange(e.target.value)} isValid={hasChanged('TimeStart')} >
                                            {hoursArray.map((hour, index) => {
                                                return <option key={index} value={moment({hour: hour}).format('HH')}>{hour}</option>;
                                            })}
                                        </FormSelect>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} lg={6}>
                                <Row>
                                    <Col xs={4}><FormLabel>Time End:</FormLabel></Col>
                                    <Col xs={8}>
                                        <FormSelect className="d-inline-block" value={(tempSlot?.TimeEnd !== '24:00' && slot.TimeStart !== '23:00') ? moment({hour: tempSlot?.TimeEnd || moment(slot.TimeStart, 'HH:mm').add({hour: 1}).format('HH')}).format('HH') : (slot.TimeStart === '23:00' && (tempSlot?.TimeStart === undefined || tempSlot?.TimeEnd === undefined)) ? '24' : moment(tempSlot?.TimeEnd, 'HH:mm').format('HH') === '00' ? '24' : moment(tempSlot?.TimeEnd, 'HH:mm').format('HH')} onChange={(e) => onEndTimeChange(e.target.value)} isValid={hasChanged('TimeEnd')} >
                                            {hoursArray.map((hour, index) => {
                                                const currentHour = moment({hour: hour});
                                                const currentStartHour = moment({hour: tempSlot?.TimeStart || slot.TimeStart});
                                                if(currentHour.subtract(currentStartHour).unix() < 0) return null;
                                                const hourText = moment({hour: hour}).add({hour: 1}).format('HH');
                                                return <option key={index} value={hourText !== '00' ? hourText : '24' }>{hourText !== '00' ? hourText : '24' }:00</option>;
                                            })}
                                        </FormSelect>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} lg={6}>
                                <Row>
                                    <Col xs={4}><FormLabel>Created On:</FormLabel></Col>
                                    <Col xs={8}>
                                        <FormControl readOnly value={tempSlot?.CreatedDate !== undefined ? moment(tempSlot.CreatedDate*1000).format('DD/MM/YYYY HH:mm') : ''} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    }
                </Card.Body>
            </Card>
        </Container>
    );
}