import React from 'react';
import { Image } from 'react-bootstrap';
import './login.css';
import logo from '../assets/ms-signin.png';
import { loginRequest } from '../msal.config';
import { useMsal } from '@azure/msal-react';

export default function Login(){
    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginPopup(loginRequest).then(() => {
            document.location.reload();
        }).catch((error) => {
            console.error(error);
            instance.loginRedirect(loginRequest).then(() => {
                document.location.reload();
            }).catch((error) => {
                console.error(error);
                alert('Unable to sign in. Please make sure you accept Nerve Radio to access your BU account.');
            });
        });
    };
    return (
        <div className="d-flex vh-100">
            <div id="loginBox" className="m-auto px-5 py-3 text-center">
                <h3 className="mb-3">Please login</h3>
                <button className="btn ms-signin-btn p-0 mb-2" onClick={handleLogin}>
                    <Image src={logo}/>
                </button>
            </div>
        </div>
    );
}