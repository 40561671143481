import { createSlice } from '@reduxjs/toolkit';

export const scheduleSlice = createSlice({
    name: 'schedule',
    initialState: {
        schedule: []
    },
    reducers: {
        setSchedule: (state, action) => {
            state.schedule = action.payload.schedule;
        },
        updateSlot: (state, action) => {
            const slotIndex = state.schedule.findIndex(s => s.ScheduleID === action.payload.id);
            state.schedule[slotIndex] = {
                ...state.schedule[slotIndex],
                Day: action.payload.Day,
                ShowID: action.payload.Show,
                TimeStart: action.payload.TimeStart,
                TimeEnd: action.payload.TimeEnd,
            };
        },
        addSlot: (state, action) => {
            state.schedule.push(action.payload);
        },
        removeSlot: (state, action) => {
            state.schedule = state.schedule.filter(s => s.ScheduleID !== action.payload.id);
        },
        removeAllShowSlots: (state, action) => {
            state.schedule = state.schedule.filter(s => s.ShowID !== action.payload.id);
        },
        removeShowsFromSchedule: (state, action) => {
            state.schedule = state.schedule.filter(s => !action.payload.ids.includes(s.ShowID));
        }
    },
});

// Action creators are generated for each case reducer function
export const { setSchedule, updateSlot, addSlot, removeSlot, removeAllShowSlots, removeShowsFromSchedule } = scheduleSlice.actions;

export default scheduleSlice.reducer;