import moment from 'moment';
import React, { useState } from 'react';
import { Card, Col, Container, FormLabel, FormSelect, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { addSlot } from '../utils/reducers/scheduleSlice';
import './newScheduleSlot.css';

export default function NewScheduleSlot({ api }){

    const daysArray = new Array(7).fill().map((_, i) => i+1);
    const hoursArray = new Array(24).fill().map((_, i) => moment({hour: i}).format('HH:mm'));

    const { day: givenDay, hour: slotHour } = useParams();
    const navigate = useNavigate();
    const [ validData, setValidData ] = useState(false);
    const shows = useSelector(state => state.shows.shows);

    const reduxDispatch = useDispatch();

    let error = null;

    if(isNaN(givenDay)) error = 'Day given is not a number';
    const slotDay = error === null && parseInt(givenDay);
    if(error === null && !daysArray.includes(slotDay)) error = 'Invalid day';
    if(slotHour.match(/^[0-9]{2}:[0-9]{2}$/g) === null) error = 'Hour given is not in the right format.';
    if(error === null && !hoursArray.includes(slotHour)) error = 'Invalid hour';

    const [ tempSlot, setTempSlot ] = useState({});

    if(error !== null) return <Container className="my-4"><h1>{error}</h1><hr></hr></Container>;

    if(!validData) setValidData(true);

    const onDayChange = (value) => {
        setTempSlot(s => { return {...s, Day: parseInt(value)};});
    };

    const onShowChanged = (show) => {
        if(show[0] === undefined) setTempSlot(s => {return {...s, ShowID: null};});
        else setTempSlot(s => { return {...s, ShowID: show[0].ShowID};});
    };

    const onStartTimeChange = (value) => {
        const newTimeStart = moment({hour: value});
        const oldTimeEnd = moment({hour: tempSlot?.TimeEnd || moment(slotHour, 'HH:mm').add({hour: 1}).format('HH')});
        setTempSlot(s => { return {...s, TimeStart: newTimeStart.format('HH:mm')};});
        if(newTimeStart - oldTimeEnd >= 0){
            if(oldTimeEnd.format('HH') === '00') return;
            setTempSlot(s => {
                const newHour = moment({hour: newTimeStart.hour()}).add({hours: 1}).format('HH');
                return {...s, TimeEnd: newHour !== '00' ? newHour+':00' : '24:00'};
            });
        }
    };

    const onEndTimeChange = (value) => {
        const newTimeEnd = value !== '24' ? moment({hour: value}).format('HH:mm') : '24:00';
        setTempSlot(s => { return {...s, TimeEnd: newTimeEnd};});
    };

    const scheduleShow = () => {
        const sDay = tempSlot?.Day || slotDay;
        const sShow = tempSlot.ShowID;
        const sTimeStart = moment({hour: tempSlot?.TimeStart || slotHour}).format('HH:mm');
        const sTimeEnd = tempSlot?.TimeEnd || moment(slotHour, 'HH:mm').add({hour: 1}).format('HH:mm');
        api.createScheduleSlot(sDay, sShow, sTimeStart, sTimeEnd === '00:00' ? '24:00' : sTimeEnd).then(newID => {
            toast.success('Show successfully scheduled');
            reduxDispatch(addSlot({
                ScheduleID: newID,
                Day: sDay,
                ShowID: sShow,
                TimeStart: sTimeStart,
                TimeEnd: sTimeEnd === '00:00' ? '24:00' : sTimeEnd,
                CreatedDate: Math.round(Date.now()/1000)
            }));
            navigate('/shows/schedule');
        }).catch(error => {
            console.warn(error);
            toast.error(error.message);
        });
    };

    const activeShows = shows.filter(show => show.Archived !== 1);

    return (
        <Container className="my-4">
            <h1>New slot on {moment().isoWeekday(slotDay).format('dddd')} at {slotHour}</h1>
            <hr />
            <Card>
                <Card.Header>
                    <h4 className="d-inline-block">Slot Details</h4>
                    {typeof tempSlot?.ShowID === 'number' && <button className="btn btn-success float-end" onClick={scheduleShow}>Schedule</button>}
                </Card.Header>
                <Card.Body>
                    {activeShows.length === 0 &&
                        <p>No shows available to schedule.</p>
                    }
                    {activeShows.length !== 0 &&
                        <Row id="slotDetails" className="text-center col-lg-9 mx-lg-auto">
                            <Col md={12} lg={6}>
                                <Row>
                                    <Col xs={4}><FormLabel>Day:</FormLabel></Col>
                                    <Col xs={8}>
                                        <FormSelect className="d-inline-block" value={tempSlot?.Day || slotDay} onChange={(e) => onDayChange(e.target.value)}>
                                            {daysArray.map((day, index) => {
                                                return <option key={index} value={day}>{moment().isoWeekday(day).format('dddd')}</option>;
                                            })}
                                        </FormSelect>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} lg={6}>
                                <Row>
                                    <Col xs={4}><FormLabel>Show:</FormLabel></Col>
                                    <Col xs={8}>
                                        <Typeahead
                                            id="showSelector"
                                            options={activeShows}
                                            labelKey={(show) => `${show.Name}`}
                                            flip
                                            onChange={onShowChanged}
                                            isValid={typeof tempSlot?.ShowID === 'number'}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} lg={6}>
                                <Row>
                                    <Col xs={4}><FormLabel>Time Start:</FormLabel></Col>
                                    <Col xs={8}>
                                        <FormSelect className="d-inline-block"  value={moment({hour: tempSlot?.TimeStart || slotHour}).format('HH')} onChange={(e) => onStartTimeChange(e.target.value)} >
                                            {hoursArray.map((hour, index) => {
                                                return <option key={index} value={moment({hour: hour}).format('HH')}>{hour}</option>;
                                            })}
                                        </FormSelect>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} lg={6}>
                                <Row>
                                    <Col xs={4}><FormLabel>Time End:</FormLabel></Col>
                                    <Col xs={8}>
                                        <FormSelect className="d-inline-block" value={(tempSlot?.TimeEnd !== '24:00' && slotHour !== '23:00') ? moment({hour: tempSlot?.TimeEnd || moment(slotHour, 'HH:mm').add({hour: 1}).format('HH')}).format('HH') : (slotHour === '23:00' && (tempSlot?.TimeStart === undefined || tempSlot?.TimeEnd === undefined)) ? '24' : moment(tempSlot?.TimeEnd, 'HH:mm').format('HH') === '00' ? '24' : moment(tempSlot?.TimeEnd, 'HH:mm').format('HH')} onChange={(e) => onEndTimeChange(e.target.value)} >
                                            {hoursArray.map((hour, index) => {
                                                const currentHour = moment({hour: hour});
                                                const currentStartHour = moment({hour: tempSlot?.TimeStart || slotHour});
                                                if(currentHour.subtract(currentStartHour).unix() < 0) return null;
                                                const hourText = moment({hour: hour}).add({hour: 1}).format('HH');
                                                return <option key={index} value={hourText !== '00' ? hourText : '24' }>{hourText !== '00' ? hourText : '24' }:00</option>;
                                            })}
                                        </FormSelect>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    }
                </Card.Body>
            </Card>
        </Container>
    );
}