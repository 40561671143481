import React from 'react';
import { useSelector } from 'react-redux';

export default function UserRole({ rid }){

    const roles = useSelector(state => state.roles.roles);
    const role = roles.find(r => r.RoleID === rid)?.Name;
    return (
        <span>{role}</span>
    );
}