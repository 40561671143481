import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Container, Image, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { graphConfig, loginRequest } from '../msal.config';
import './navbar.css';
import * as Helpers from '../utils/helpers';
import { Link, useNavigate } from 'react-router-dom';
import presenterIcon from '../assets/presenter-icon.jpg';
import iconTick from '../assets/icon_tick.png';
import iconCross from '../assets/icon_cross.png';
import { useSelector } from 'react-redux';

const navItems = [
    {
        type: 'category',
        title: 'Manage Shows',
        roles: ['Committee', 'Admin'],
        items: [
            {
                type: 'item',
                title: 'View Schedule',
                link: '/shows/schedule'
            },
            {
                type: 'item',
                title: 'View All Shows',
                link: '/shows/list'
            }
        ]
    },
    {
        type: 'category',
        title: 'Manage Users',
        roles: ['Committee', 'Admin'],
        items: [
            {
                type: 'item',
                title: 'View All Users',
                // roles: ['Admin'],
                link: '/users/list'
            },
            // {
            //     type: 'item',
            //     title: 'View Committee',
            //     roles: ['Admin'],
            //     link: '/users/committee',
            //     disabled: true
            // },
            // {
            //     type: 'item',
            //     title: 'View Presenters',
            //     link: '/users/presenters',
            //     disabled: true
            // },
            {
                type: 'divider'
            },
            {
                type: 'item',
                title: 'DEPRECATED: Quick Password Reset',
                link: '/users/qpr',
                disabled: true
            }
        ]
    },
    {
        type: 'category',
        title: 'Support',
        items: [
            // {
            //     type: 'item',
            //     title: 'Open a Support Ticket',
            //     link: '/support/new',
            //     disabled: true
            // },
            // {
            //     type: 'item',
            //     title: 'View Existing Tickets',
            //     link: '/support/list',
            //     disabled: true
            // },
            // {
            //     type: 'divider'
            // },
            {
                type: 'item',
                title: 'Tech Guides',
                roles: ['Admin'],
                link: '/support/guides/tech',
                disabled: true
            },
            {
                type: 'item',
                title: 'Committee Guides',
                roles: ['Committee', 'Admin'],
                link: '/support/guides/committee',
                disabled: true
            },
            {
                type: 'item',
                title: 'Presenter Guides',
                link: '/support/guides/presenters',
                disabled: true
            },
        ]
    }
];

export default function NavBar(){
    const navigate = useNavigate();

    const { instance } = useMsal();
    const [ name, setName ] = useState('');
    const [ picture, setPicture ] = useState(null);
    const [ myriadLinkActive, setMyriadLinkActive ] = useState(false);
    
    const activeAccount = instance.getActiveAccount();

    const profile = useSelector(state => state.profile.profile);
    const roles = useSelector(state => state.roles.roles);
    const shows = useSelector(state => state.shows.shows);
    const myUserObject = profile;
    const nerveRole = roles.find(r => r.RoleID === myUserObject.Role);
    const myShows = shows.filter(s => (s.Host === myUserObject.UserID && s.Archived === 0) || (s.Hosts.includes(myUserObject.UserID) && s.Archived === 0));

    useEffect(() => {
        (async () => {
            const response = await instance.acquireTokenSilent({...loginRequest, account: activeAccount});
            const headers = new Headers();
            const bearer = `Bearer ${response.accessToken}`;
            headers.append('Authorization', bearer);
            const options = {
                method: 'GET',
                headers: headers
            };
            await fetch(graphConfig.graphMeEndpoint+'photo', options).then(async (response) => {
                if((await response.json()).error?.code === 'ImageNotFound') throw new Error('ImageNotFound');
                await fetch(graphConfig.graphMeEndpoint+'photo/$value', options).then(async (response) => {
                    const blob = await response.blob();
                    const img = URL.createObjectURL(blob);
                    setPicture(img);
                }).catch(e => {
                    throw new Error(e);
                });
            }).catch((error) => {
                if(error.message === 'ImageNotFound') setPicture(presenterIcon) && console.warn('No Image found, using default presenter icon.');
                else console.warn(error);
            });

            const myriadLink = await Helpers.isMyriadLinkActive();
            setMyriadLinkActive(myriadLink);
        })();
        setName(activeAccount.name);
    }, []);

    const handleLogout = () => {
        instance.logoutPopup();
    };

    const goToProfile = () => {
        navigate('/profile');
    };

    return (
        <Navbar bg="dark" expand="lg" variant="dark">
            <Container>
                <Navbar.Brand title={'v'+process.env.REACT_APP_VERSION.split('-')[0]+(process.env.NODE_ENV === 'development' ? '-dev' : '')}><Link to="/">Nerve Manager</Link></Navbar.Brand>
                <Navbar.Toggle aria-controls="nerve-navbar" />
                <Navbar.Collapse id="nerve-navbar">
                    <Nav>
                        {myShows.length === 1 &&
                            <Nav.Link onClick={() => {
                                navigate('/shows/'+myShows[0].ShowID);
                            }}>
                                {myShows[0].Name}
                            </Nav.Link>
                        }
                        {myShows.length > 1 &&
                            <NavDropdown title={'My Shows'}>
                                {myShows.map((v, i) => {
                                    return (
                                        <NavDropdown.Item key={i} onClick={() => {
                                            navigate('/shows/'+v.ShowID);
                                        }}>
                                            {v.Name}
                                        </NavDropdown.Item>
                                    );
                                })}
                            </NavDropdown>
                        }
                        {navItems.map((navItem, index) => {
                            if(navItem.type === 'category'){
                                if(navItem.roles !== undefined){
                                    if(navItem.roles.findIndex(i => i === nerveRole?.Name) === -1) return null;
                                }
                                return (
                                    <NavDropdown title={navItem.title} key={index}>
                                        {navItem.items.map((item, itemIndex) => {
                                            if(item.roles !== undefined){
                                                if(item.roles.findIndex(i => i === nerveRole?.Name) === -1) return null;
                                            }
                                            if(item.type === 'item') return (
                                                <NavDropdown.Item disabled={item?.disabled || false} key={itemIndex} onClick={() => {
                                                    navigate(item.link);
                                                }}>
                                                    {item.title}
                                                </NavDropdown.Item>
                                            );
                                            else if(item.type === 'divider') return (
                                                <NavDropdown.Divider key={itemIndex} />
                                            );
                                            else return null;
                                        })}
                                    </NavDropdown>
                                );
                            }
                            return (
                                <span key={index}></span>
                            );
                        })}
                    </Nav>
                    <Nav className="ms-auto">
                        <NavDropdown title={name}>
                            <NavDropdown.Item onClick={goToProfile}><Image src={picture} roundedCircle thumbnail className="profilePicture me-2"/>My Profile</NavDropdown.Item>
                            <NavDropdown.ItemText className="text-center" title="Full access lets you access show recordings &amp; user management. If not available, seek tech support."><Image src={myriadLinkActive ? iconTick : iconCross} height={12} className="me-2"/>Full Access</NavDropdown.ItemText>
                            <NavDropdown.Divider />
                            <NavDropdown.Item className="text-danger fw-bold" onClick={handleLogout}>Sign Out</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}