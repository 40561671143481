import { useMsal } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import presenterIcon from '../assets/presenter-icon.jpg';
import { graphConfig, loginRequest } from '../msal.config';

export default function UserIcon({ uid }) {

    const [picture, setPicture] = useState(null);
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    useEffect(() => {
        //Perform Picture Request
        instance.acquireTokenSilent({ ...loginRequest, account: activeAccount }).then(response => {

            const headers = new Headers();
            const bearer = `Bearer ${response.accessToken}`;
            headers.append('Authorization', bearer);
            const options = {
                method: 'GET',
                headers: headers
            };

            fetch(graphConfig.graphUsersEndpoint + uid + '/photo', options).then(async (response) => {
                if (!response.ok) throw new Error('ImageNotFound');
                await fetch(graphConfig.graphUsersEndpoint + uid + '/photo/$value', options).then(async (response) => {
                    const blob = await response.blob();
                    const img = URL.createObjectURL(blob);
                    setPicture(img);
                }).catch(e => {
                    throw new Error(e);
                });
            }).catch((error) => {
                if (error.message === 'ImageNotFound') setPicture(presenterIcon) && console.warn('No Image found, using default presenter icon.');
                else console.warn(error);
            });
        });

    }, []);

    return (
        <Image src={picture || presenterIcon} height={64} width={64} roundedCircle thumbnail />
    );
}