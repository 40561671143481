import { useMsal } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { graphConfig, loginRequest } from '../msal.config';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './userFinder.css';

export default function UserFinder({ passedRef, className, placeholder, excludeUsers = [], includeOnly = [], onUserSelected = _ => _ }){
    
    const [ msalOptions, setMsalReqOptions ] = useState(null);
    const [ isSearching, setSearching ] = useState(false);
    const [ usersFound, setUsersFound ] = useState([]);
    const { instance } = useMsal();

    useEffect(() => {
        const activeAccount = instance.getActiveAccount();
        instance.acquireTokenSilent({...loginRequest, account: activeAccount}).then(response => {
            const headers = new Headers();
            const bearer = `Bearer ${response.accessToken}`;
            headers.append('Authorization', bearer);
            headers.append('ConsistencyLevel', 'eventual');
            const options = {
                method: 'GET',
                headers: headers
            };
            setMsalReqOptions(options);
        });
    }, []);

    return (
        <AsyncTypeahead
            className={className}
            id={'UserFinder'}
            inputProps={{id: 'UserFinder'}}
            isLoading={isSearching}
            labelKey={o => `${o.givenName} ${o.surname} [${o.userPrincipalName}]`}
            options={usersFound}
            onSearch={(name) => {
                setSearching(true);
                if(includeOnly.length !== 0){
                    setUsersFound(includeOnly.filter(u => u.Name.toLowerCase().includes(name.toLowerCase()) || u.Email.toLowerCase().includes(name.toLowerCase())).map(u => {return {id: u.UserID, givenName: u.Name.split(' ')[0], surname: u.Name.split(' ')[1], userPrincipalName: u.Email};}));
                    setSearching(false);
                    return;
                }
                if(msalOptions === null) return;
                fetch(graphConfig.graphUsersEndpoint+'?search="displayName:'+name+'" OR "mail:'+name+'"&$orderby=displayName&$count=true&$top=999', msalOptions).then(async (response) =>{
                    const msData = await response.json();
                    if(includeOnly.length > 0) setUsersFound(msData.value.filter(u => {if(includeOnly.findIndex(u1 => u.id === u1.UserID) !== -1) return true; else return false;}));
                    else setUsersFound(msData.value.filter(u => {if((excludeUsers.findIndex(u1 => u.id === u1.UserID) === -1) && (u.userPrincipalName.endsWith('bournemouth.ac.uk'))) return true; else return false;}));
                    setSearching(false);
                });
            }}
            onChange={(selectionChange) => {
                if(selectionChange.length === 1) {
                    onUserSelected(selectionChange[0]);
                }
            }}
            delay={500}
            placeholder={placeholder || 'Student Name or Email Address'}
            flip
            ref={passedRef}
        />
    );
}