import { createSlice } from '@reduxjs/toolkit';

export const usersSlice = createSlice({
    name: 'users',
    initialState: {
        users: []
    },
    reducers: {
        setUsers: (state, action) => {
            state.users = action.payload.users;
        },
        addUser: (state, action) => {
            state.users.push(action.payload);
        },
        removeUser: (state, action) => {
            state.users = state.users.filter(u => u.UserID !== action.payload.id);
        },
        updateUser: (state, action) => {
            const userIndex = state.users.findIndex(u => u.UserID === action.payload.id);
            state.users[userIndex] = {
                ...state.users[userIndex],
                Role: action.payload.Role,
            };
        },
        archiveUser: (state, action) => {
            const userIndex = state.users.findIndex(u => u.UserID === action.payload.id);
            state.users[userIndex] = {
                ...state.users[userIndex],
                Archived: 1,
            };
        },
        restoreUser: (state, action) => {
            const userIndex = state.users.findIndex(u => u.UserID === action.payload.id);
            state.users[userIndex] = {
                ...state.users[userIndex],
                Archived: 0,
            };
        },
    },
});

// Action creators are generated for each case reducer function
export const { setUsers, addUser, removeUser, updateUser, archiveUser, restoreUser } = usersSlice.actions;

export default usersSlice.reducer;